// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import PageScrollTop from './component/PageScrollTop';
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Loadable from 'react-loadable';


const Loading = () => <div></div>;
const AsyncMainDemo = Loadable({
    loader: () => import('./home/MainDemo'),
    loading: Loading,
  });

const AsyncService = Loadable({
    loader: () => import('./elements/Service'),
    loading: Loading,
});

const AsyncContact = Loadable({
    loader: () => import('./elements/Contact'),
    loading: Loading,
});
const AsyncFaq = Loadable({
    loader: () => import('./elements/Faq'),
    loading: Loading,
});
const AsyncAbout = Loadable({
    loader: () => import('./elements/About'),
    loading: Loading,
});
const AsyncPrivacyPolicy = Loadable({
    loader: () => import('./elements/Privacy'),
    loading: Loading,
});
const AsyncTermsCondition = Loadable({
    loader: () => import('./elements/TermsCondition'),
    loading: Loading,
});
const AsyncPortfolio = Loadable({
    loader: () => import('./blocks/Portfolio'),
    loading: Loading,
});
const Asyncerror404 = Loadable({
    loader: () => import('./elements/error404'),
    loading: Loading,
});

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                
                <PageScrollTop>
                    <Switch>                       
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={AsyncMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/services`} component={AsyncService}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={AsyncContact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/faq`} component={AsyncFaq}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about-us`} component={AsyncAbout}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={AsyncPrivacyPolicy}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/terms-condition`} component={AsyncTermsCondition}/>                      
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio/`} component={AsyncPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio/:id`} component={AsyncPortfolio}/>                
                        <Route path={`${process.env.PUBLIC_URL}/404`} component={Asyncerror404}/>
                        <Route component={Asyncerror404}/>
                    </Switch>
                </PageScrollTop>                
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();